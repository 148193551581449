/* eslint-disable import/prefer-default-export */
import ApiService from '@/services/ApiService';
import store from '@/store/store';

class MessengerService {
  static async getUserData() {
    const tokkoChatHost = localStorage.getItem('tokkoChatHost');
    const composedURL = `${tokkoChatHost}/authorize/oauth`;
    const requestOptions = {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('jwt'),
      },
    };

    const response = await fetch(composedURL, requestOptions);
    if (!response.ok) {
      throw new Error(response.message || 'Request failed');
    }

    const data = await response.json();

    return data;
  }

  static async getInquiry(channelId, pageNumber) {
    const composedURL = `/chat_entre_colegas/inquiry?channel_id=${channelId}&channel_type=Colleague&page=${pageNumber}&objects_per_page=5`;
    const response = await ApiService.get(composedURL);
    return response;
  }

  static async getUserContacts(
    searchQuery,
    reachedEndOfScroll,
    withColleagues
  ) {
    const { accessToken } = store.getState().MessengerSlice.data;
    const { tokkoMessengerURL } = store.getState().UserPreferencesSlice;
    return fetch(
      `${tokkoMessengerURL}/users/me/contacts?objects_per_page=10&page=${reachedEndOfScroll}&word=${searchQuery}&colleagues=${withColleagues}`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => data)
      .catch((error) => {
        throw error;
      });
  }

  // eslint-disable-next-line camelcase
  static async postUserData(name, email, image, cellphone_number) {
    const { accessToken } = store.getState().MessengerSlice.data;
    const { tokkoMessengerURL } = store.getState().UserPreferencesSlice;

    const bodyContent = JSON.stringify({
      name,
      email,
      image,
      cellphone_number,
    });
    return fetch(`${tokkoMessengerURL}/users/me`, {
      method: 'POST',
      credentials: 'include',
      body: bodyContent,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .catch((error) => {
        throw error;
      });
  }

  static async sentProdMessage(data) {
    const { accessToken } = store.getState().MessengerSlice.data;
    const { tokkoMessengerURL } = store.getState().UserPreferencesSlice;

    const bodyContent = JSON.stringify(data);

    return fetch(`${tokkoMessengerURL}/users/me/messages/new`, {
      method: 'POST',
      credentials: 'include',
      body: bodyContent,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .catch((error) => {
        throw error;
      });
  }

  static async deleteUnreadsMessages() {
    const { accessToken } = store.getState().MessengerSlice.data;
    const { tokkoMessengerURL } = store.getState().UserPreferencesSlice;
    return fetch(`${tokkoMessengerURL}/users/me/unreads`, {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => data)
      .catch((error) => {
        throw error;
      });
  }

  static async duplicateContacts(webContactID) {
    const composedURL = `/api3/marketing/webcontact/duplicate_contacts_by_web_contact?web_contact_id=${webContactID}`;
    const response = await ApiService.get(composedURL);
    return response;
  }

  static async messengerManager(data) {
    const composedURL = `/api3/webcontact/messenger_manager`;
    const response = await ApiService.post(composedURL, data);
    return response;
  }

  static async getWhatsappQrCode() {
    const { accessToken } = store.getState().MessengerSlice.data;
    const { tokkoMessengerURL } = store.getState().UserPreferencesSlice;

    return fetch(`${tokkoMessengerURL}/users/whatsapp/qrcode`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => data)
      .catch((error) => {
        console.error('Error fetching QR code:', error);
        throw error;
      });
  }

  static async getAgentsChat() {
    const composedURL = '/api3/agent/';
    const response = await ApiService.get(composedURL);
    return response;
  }

  static async getPropertiesToShare(data) {
    const { q, page } = data;
    let composedURL = '/api3/property';
    const params = [];

    if (q) {
      params.push(`q=${encodeURIComponent(q)}`);
    }

    if (page) {
      params.push(`page=${page}`);
    }

    if (params.length > 0) {
      composedURL += `/?${params.join('&')}`;
    }
    const response = await ApiService.get(composedURL);
    return response;
  }

  static async getDevelopmentsToShare(data) {
    const { q, page } = data;
    let composedURL = '/api3/developments';
    const params = [];

    if (q) {
      params.push(`searchword=${encodeURIComponent(q)}`);
    }

    if (page) {
      params.push(`page=${page}`);
    }

    if (params.length > 0) {
      composedURL += `?${params.join('&')}`;
    }

    const response = await ApiService.get(composedURL);
    return response;
  }

  static async getContact(id) {
    const composedURL = `/api3/contact/?id=${id}`;
    const response = await ApiService.get(composedURL);
    return response;
  }

  static async getLeadsStatus() {
    const composedURL = '/api3/contact/leads_status';
    const response = await ApiService.get(composedURL);
    return response;
  }

  static async changeLeadStatus(data) {
    const composedURL = `/api3/contacts/${data.id}`;
    const response = await ApiService.patch(composedURL, data.body);
    return response;
  }

  static async getFichaInfoUrl(data) {
    const { id, contactId } = data;
    const composedURL = `/api3/property/get_ficha_info_url?properties_id=${id}&contact_id=${contactId}&is_edited=${true}`;
    const response = await ApiService.get(composedURL);
    return response;
  }

  static async getWhatsappIsLinked() {
    const composedURL = `/api3/tokko_chat/whatsapp/is_linked`;
    const response = await ApiService.get(composedURL);
    return response;
  }

  static async getMembers(data) {
    const { q, page } = data;
    let composedURL = '/api3/agent?only_actives=true';
    const params = [];

    if (q) {
      params.push(`q=${encodeURIComponent(q)}`);
    }

    if (page) {
      params.push(`page=${page}`);
    }

    if (params.length > 0) {
      composedURL += `?${params.join('&')}`;
    }

    const response = await ApiService.get(composedURL);
    return response;
  }
}

export default MessengerService;
